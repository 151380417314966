import styles from "../../../styles/layout/BlockTakeItWithYou/TakeItWithYou.module.scss";
import {
  StraightPipes,
  StraightPipesSecond,
  DoublePipesMain,
} from "../../Widgets/WidgetsPipe/Pipes";
import { dataDiscript } from "./dataTakeItWithYou";
import { CornerIcon } from "../../Widgets/WidgetsPipe/Pipes";
// import takeBeer from "/";

const TakeItWithYou = () => {
  return (
    <div className={styles.container}>
      <div className={styles.blockTitle}>
        <CornerIcon />
        <h1 className={styles.textTitel}>
          {"Берите с собой".toLocaleUpperCase()}
        </h1>
      </div>
      <div className={styles.blockDiscript}>
        <h1 className={styles.textDiscriptTitel}>{dataDiscript?.discript}</h1>
        <div className={styles.blockDiscriptTitel}>
          <h2 className={styles.textDiscriptTitel}>{dataDiscript?.timeWork}</h2>
        </div>
        <h3 className={styles.textDiscrTimeContent}>{dataDiscript?.time}</h3>
      </div>
      <div className={styles.blockImgRestaurants}>
        <img
          // src={
          //   "https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/restaurants/image_979.png"
          // }
          src={"/img/TakeItWithYou/takeBeer.png"}
        />
      </div>
    </div>
  );
};

export { TakeItWithYou };
