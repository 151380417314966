import styles from "../../../styles/layout/BlockAboutFood/AboutFood.module.scss";
import { discript, dataDiscriptPhoto } from "./dataAboutFood";
import { CornerIcon } from "../../Widgets/WidgetsPipe/Pipes";
import menuRest from "../../../data/Rectangle 94.pdf";
import menuBar from "../../../data/Rectangle 94.pdf";
// import {Link} from 'react-router-dom';

const AboutFood = () => {
  const HoverMiniBlock = ({ titel, discript }) => {
    return (
      <div className={styles.blockHoverMiniDiscript}>
        <h1 className={styles.textHoverTitelMain}>
          {titel.toLocaleUpperCase()}
        </h1>
        <h1 className={styles.textHoverDiscriptMain}>{discript}</h1>
      </div>
    );
  };

  const BlockDiscriptAboutFoodSwitch = () => {
    return (
      <>
        <div className={styles.blockDiscriptPhoto}>
          {dataDiscriptPhoto.slice(1, 5).map((elem, index) => (
            <div key={index} className={styles.blockContentDiscriptPhoto}>
              <img src={elem?.photo} />
              <HoverMiniBlock titel={elem?.titel} discript={elem?.discript} />
            </div>
          ))}
        </div>
        <div className={styles.blockDiscriptMobile}>
          {dataDiscriptPhoto.slice(1, 4).map((elem, index) => (
            <div
              key={index}
              style={
                index % 2 === 0
                  ? {
                      flexDirection: "row",
                    }
                  : { flexDirection: "row-reverse" }
              }
              className={styles.blockContentMobDiscriprt}
            >
              <div className={styles.blockPhotoMobile}>
                <img src={elem?.photo} />
              </div>
              <div className={styles.blockTextHMobile}>
                <h1 className={styles.textMobileDiscriptHTitel}>
                  {elem?.titel.toLocaleUpperCase()}
                </h1>
                <h2 className={styles.textMobileDiscriptHDis}>
                  {elem?.discript}
                </h2>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  const onButtonClick = (path, name) => {
    const pdfUrl = path;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = name; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div id="memu" className={styles.container}>
      <div className={styles.blockTitle}>
        <CornerIcon />
        <h1 className={styles.textTitel}>НАСЛАДИТЕСЬ</h1>
      </div>
      <div className={styles.blockDiscript}>
        <h1 className={styles.textDiscript}>{discript}</h1>
        <div className={styles.blockDiscriptButton}>
          <buttton
            // onClick={() => onButtonClick(menuRest, "menuRest.pdf")}
            className={styles.buttonDiscript}
          >
            МЕНЮ РЕСТОРАНА
          </buttton>
          <buttton
            // onClick={() => onButtonClick(menuBar, "menuBar.pdf")}
            className={styles.buttonDiscript}
          >
            БАРНАЯ КАРТА
          </buttton>
        </div>
      </div>
      <div className={styles.blockPhotoAboutFood}>
        <div className={styles.blockMainPhotoAboutFood}>
          <img
            // src={
            //   "https://krasnayapolyanaresort.ru/assets/images/blog/frirajd_7.png"
            // }
            src={dataDiscriptPhoto[0].photo}
          />
          <div className={styles.blockTextMainAboutFood}>
            <h1 className={styles.textTitelMainPhoto}>
              {dataDiscriptPhoto[0].titel.toUpperCase()}
            </h1>
            <h1 className={styles.textDiscriptMainPhoto}>
              {dataDiscriptPhoto[0].discript}
            </h1>
          </div>
          <div className={styles.blockHoverMainAboutFood}>
            <h1 className={styles.textHoverTitelMain}>
              {dataDiscriptPhoto[0].titel.toUpperCase()}
            </h1>
            <h1 className={styles.textHoverDiscriptMain}>
              {dataDiscriptPhoto[0].discript}
            </h1>
          </div>
        </div>
        <BlockDiscriptAboutFoodSwitch />
      </div>
    </div>
  );
};

export { AboutFood };
