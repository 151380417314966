import styles from "../../../styles/layout/Footer/Footer.module.scss";
import {
  StraightPipes,
  StraightPipesSecond,
  TheCanister,
  StraightPipesFooter,
  StraightPipesSecondFotter,
  DoublePipesFooter,
} from "../../Widgets/WidgetsPipe/Pipes";
import { useEffect, useState } from "react";
import { dataFooter } from "./dataFooter";
import { CornerIcon } from "../../Widgets/WidgetsPipe/Pipes";
//icon
import iconMap from "../../../assets/icon/map/point-2.svg";
// import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";

const Footer = () => {
  const [endLine, setEndLine] = useState("594px");
  // const YOUR_API_KEY = "6b5e2e93-adaa-42ed-9552-cf9d09045eb6";
  // const LOCATION = {
  //   center: [37.588144, 55.733842],
  //   zoom: 9,
  // };

  return (
    <div id="contact" className={styles.container}>
      <div className={styles.blockTitle}>
        <CornerIcon />
        <h1 className={styles.textTitel}>{"Контакты".toLocaleUpperCase()}</h1>
      </div>
      <div className={styles.blockDiscript}>
        <div className={styles.blockContentDiscript}>
          <h1 className={styles.textTitelDiscript}>
            {dataFooter?.titelTimeWork}
          </h1>
          <h2 className={styles.textDiscriptW}>{dataFooter?.timeWork}</h2>
        </div>
        <div className={styles.blockContentDiscript}>
          <h1 className={styles.textTitelDiscript}>
            {dataFooter?.titelAdress}
          </h1>
          <h2 className={styles.textDiscriptR}>{dataFooter?.adress}</h2>
        </div>
        <div className={styles.blockContentDiscript}>
          <h1 className={styles.textTitelDiscript}>{dataFooter?.titelPhone}</h1>
          <h2 className={styles.textDiscriptR}>{dataFooter?.phone}</h2>
        </div>
      </div>
      <div className={styles.blockImgMap}>
        <div style={{ width: "100%", height: "100%" }}>
          <YMaps>
            <Map
              className={styles.mapStyles}
              // defaultState={{ center: [43.669775, 40.263063], zoom: 12 }}
              defaultState={{ center: [43.552409, 39.981642], zoom: 10 }}
            >
              <Placemark
                defaultGeometry={[43.669775, 40.263063]}
                options={{
                  iconLayout: "default#image",
                  iconImageHref: iconMap,
                  iconImageSize: [96.15, 137.92],
                  iconImageOffset: [-96.15, -137.92],
                }}
              />
            </Map>
            {/* options={{
                iconLayout: 'default#image',
                iconImageHref: IconGeoPosition,
                iconImageSize: [40, 40],
                iconImageOffset: [100, -10] */}
          </YMaps>
        </div>
      </div>
      <div className={styles.blockEndFooter}>
        <h1 className={styles.textblockEndFooter}>Птицы захмелели, 2025</h1>
      </div>
    </div>
  );
};

export { Footer };
