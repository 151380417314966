import styles from "../../../styles/layout/BlockPhilosophy/Philosophy.module.scss";
import { PcPipes } from "../../Widgets/WidgetsPipe/Pipes";
import { discriptPhilosophy, contentBear } from "./dataPhilosophy";

const Philosophy = () => {
  return (
    <div id="rest" className={styles.container}>
      <div className={styles.blockTitle}>
        <h1 className={styles.textTitle}>ПОЗНАКОМЬТЕСЬ С НАШЕЙ ФИЛОСОФИЕЙ</h1>
      </div>
      <div className={styles.contentPhilosophy}>
        <div className={styles.blockDiscript}>
          <h1 className={styles.textDiscriptPhilosophy}>
            {discriptPhilosophy}
          </h1>
        </div>
        <div className={styles.contentBlockBear}>
          {contentBear.map((elem, index) => (
            <div key={index} className={styles.blockContentBear}>
              <h1 className={styles.textTitelContentBear}>
                {elem?.titel.toLocaleUpperCase()}
              </h1>
              <h1 className={styles.textDiscrContentBear}>{elem?.discript}</h1>
            </div>
          ))}
        </div>

        <img
          className={styles.imgCaraftBear}
          src={
            // "https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/craftbear998.jpg"
            "/img/Philosophy/image 998.jpg"
          }
          alt={"image 998.jpg"}
        />
      </div>
      <PcPipes />
    </div>
  );
};

export { Philosophy };
