import styles from "../../../styles/layout/BlockMakingBeer/MakingBeer.module.scss";
import { CornerIcon } from "../../Widgets/WidgetsPipe/Pipes";

import {
  StraightPipes,
  StraightPipesSecond,
  DoublePipesMain,
} from "../../Widgets/WidgetsPipe/Pipes";
import { discript } from "./dataMakingBeer";

const MakingBeer = () => {
  return (
    <div id="beer" className={styles.container}>
      <div className={styles.blockTitle}>
        <CornerIcon />
        <h1 className={styles.textTitel}>
          {"Узнайте, как мы варим пиво".toLocaleUpperCase()}
        </h1>
      </div>
      <div className={styles.blockDiscript}>
        <h1 className={styles.textDiscript}>{discript}</h1>
      </div>
      <div className={styles.blockImgCraftBeer}>
        <img />
      </div>
      <div className={styles.blockScroll}>
        <img />
      </div>
    </div>
  );
};

export { MakingBeer };
